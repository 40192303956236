import axios from "axios";

export default {
  get: (params) => {
    return axios.get("/w1/notice/all",{ params: params });
  },
  getIcon: (params) => {
    return axios.get("/w1/notice/allIcon",{params:params});
  },
  getDetail: (id) => {
    return axios.get(`/w1/notice/getDetail/${id}`);
  },
};
